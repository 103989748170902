

<template>
    <v-main fluid class="mt-n6">
        <v-form ref="form"
                class="w-100 pa-8">
            <v-container v-if="is2FA == false">
                <v-row>
                    <v-col class="pb-0 mb-0">
                        <v-row class="mb-60">
                            <v-tabs v-model="tab" grow flat color="secondary">
                                <v-tab key="1" href="#Tab-1" class="accent lighten-5 taborder-1 font-weight-bolder">
                                    {{$t("AL_ChangePassword")}}
                                </v-tab>
                                <v-tab-item id="Tab-1" key="1"
                                            value="Tab-1" class="grey lighten-4">
                                    <v-expansion-panels accordion multiple tile v-model="panel">
                                        <v-expansion-panel id="1" key="0" value="0">
                                            <v-expansion-panel-header class="font-weight-bold py-6 accent lighten-5" style="min-height: 0 !important">
                                                {{$t("AL_ChangePassword")}}
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content class="py-5" style="">
                                                <v-text-field id="email"
                                                              ref="email"
                                                              autocomplete="off"
                                                              v-model="model.email"
                                                              v-bind:error="errors.email"
                                                              dense
                                                              outlined
                                                              hide-details
                                                              color="secondary"
                                                              append-icon="email"
                                                              v-bind:placeholder="$t('FL_Email')"
                                                              :label="$t('FL_Email')"
                                                              disabled="disabled"
                                                              class="mb-3 fieldDisabled"></v-text-field>

                                                <v-text-field id="password"
                                                              ref="password"
                                                              autocomplete="off"
                                                              v-model="model.password"
                                                              v-bind:error="errors.password"
                                                              dense
                                                              outlined
                                                              hide-details
                                                              color="secondary"
                                                              v-bind:append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                              v-on:click:append="showpassword = !showpassword"
                                                              v-bind:type="showpassword ? 'text' : 'password'"
                                                              v-bind:placeholder="$t('FL_Password')"
                                                              :label="$t('FL_Password')"
                                                              class="mb-3"></v-text-field>

                                                <v-text-field id="newpassword1"
                                                              ref="newpassword1"
                                                              autocomplete="off"
                                                              v-model="model.newpassword1"
                                                              v-bind:error="errors.newpassword1"
                                                              dense
                                                              outlined
                                                              hide-details
                                                              color="secondary"
                                                              v-bind:append-icon="newShowpassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                              v-on:click:append="newShowpassword = !newShowpassword"
                                                              v-bind:type="newShowpassword ? 'text' : 'password'"
                                                              v-bind:placeholder="$t('FL_newpassword1')"
                                                              :label="$t('FL_newpassword1')"
                                                              class="mb-3"></v-text-field>

                                                <v-text-field id="newpassword2"
                                                              ref="newpassword2"
                                                              autocomplete="off"
                                                              v-model="model.newpassword2"
                                                              v-bind:error="errors.newpassword2"
                                                              dense
                                                              outlined
                                                              hide-details
                                                              color="secondary"
                                                              v-bind:append-icon="newShowpasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                                              v-on:click:append="newShowpasswordConfirm = !newShowpasswordConfirm"
                                                              v-bind:type="newShowpasswordConfirm ? 'text' : 'password'"
                                                              v-bind:placeholder="$t('FL_newpassword2')"
                                                              :label="$t('FL_newpassword2')"
                                                              class="mb-3"></v-text-field>


                                                <v-row class="my-3 d-flex justify-content-center align-items-center">
                                                    <v-col cols="auto" class="mr-0 pr-0">
                                                        <v-img id="captchaimg" height="106" width="300" :src="captchaUrl">
                                                            <template v-slot:placeholder>
                                                                <v-skeleton-loader class="my-auto"
                                                                                   type="image" height="106" width="300"></v-skeleton-loader>
                                                            </template>
                                                        </v-img>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <v-btn depressed icon v-on:click="ReloadCaptcha()" color="accent">
                                                            <v-icon>cached</v-icon>
                                                        </v-btn>
                                                    </v-col>

                                                </v-row>

                                                <v-text-field v-model="model.captcha" v-bind:error="errors.captcha"
                                                              dense outlined hide-details color="secondary" append-icon="mdi-shield-check"
                                                              v-bind:placeholder="$t('FL_SecurityCode')"
                                                              :label="$t('FL_SecurityCode')"
                                                              class="mb-5"></v-text-field>

                                                <v-btn depressed block color="primary white--text" v-on:click="ChangePassword()">{{$t("AL_ChangePassword")}}</v-btn>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-tab-item>
                            </v-tabs>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
            <v-container class="p-0 px-2" v-else>
                <v-row class="d-flex justify-content-center">
                    <v-col cols="12" sm="10" md="8" lg="5">
                        <div class="bg-white border p-5 mt-5">
                            <h1 class="display-3">{{$t("TH_ChangePasswordVerification")}}</h1>
                            <p class="caption">{{$t("IM_EnterSMSVerificationCode")}}</p>
                            <hr />

                            <v-text-field v-model="model.verificationcode" v-bind:error="errors.verificationcode"
                                          dense outlined hide-details color="accent" append-icon="lock"
                                          v-bind:placeholder="$t('FL_VerificationCode')"
                                          :label="$t('FL_VerificationCode')"
                                          class="mb-3"></v-text-field>

                            <v-btn depressed block color="primary white--text" v-on:click="Authenticate()">{{$t("AL_Send")}}</v-btn>
                            <hr />
                        </div>
                    </v-col>
                </v-row>
            </v-container>

            <v-dialog v-model="processing" persistent>
                <v-card color="transparent" dark class="py-5">
                    <v-card-text class="text-center py-5">
                        <v-progress-circular indeterminate
                                             :size="50"
                                             color="white"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-form>
    </v-main>
</template>

<style>
    .v-dialog {
        box-shadow: none !important;
    }
</style>

<script>
    import { FormMixIn } from '@/MixIns/FormMixins';
    export default {
        mixins: [FormMixIn],
        name: "Login",
        data: function ()
        {
            return {
                processing: false,
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                imgURL: '',

                is2FA: false,

                model: {
                    email: '',
                    password: '',
                    newpassword1: '',
                    newpassword2: '',
                    verificationcode: '',
                    captcha: ''
                },

                errors: {
                    email: false,
                    password: false,
                    newpassword1: false,
                    newpassword2: false,
                    verificationcode: false,
                    captcha: false
                },
                keys: {
                    'email': 'FL_Email',
                    'password': 'FL_Password',
                    'newpassword1': 'FL_newpassword1',
                    'newpassword2': 'FL_newpassword2',
                    'verificationcode': 'FL_VerificationCode',
                    'captcha': 'FL_SecurityCode'
                },
                customErrorText: [],
                captchaUrl: '/pages/BlankCaptcha.bmp',
                showpassword: false,
                newShowpassword: false,
                newShowpasswordConfirm: false,
                tab: 'Tab-2',
                panel: [0],
                pageInputs: {
                    'Login': ['email', 'password', 'newpassword1', 'newpassword2', 'captcha'],
                    '2FA': ['verificationcode'],
                },
                captchTimer: 180,
            };
        },
        methods: {
            ChangePassword()
            {
                if (this.ValidateBeforeAction('Login'))
                {
                    this.processing = true;
                    var payload = {
                        action: 'ChangePassword',
                        formdata: {
                            formInputs: { email: this.model.email, password: this.model.password, newpassword1: this.model.newpassword1, newpassword2: this.model.newpassword2  },
                        },
                        captcha: this.model.captcha,
                    };
                    var self = this;
                    this.$store.dispatch("AccountAction", payload)
                        .then(response =>
                        {
                            if (response.data.errorCode == 0)
                            {
                                this.model.verificationcode = response.data.result.verificationcode;
                                this.is2FA = true;
                                this.ClearErrors();
                                self.ShowDialog(this.$root.$i18n.t('IM_PasswordChangedHomePage'), 'B', 'dialog', undefined, function () {
                                    self.$router.push({ name: 'home' });
                                });
                            }
                            if (response.data.errorCode == 1)
                            {
                                this.model.verificationcode = response.data.result.verificationcode;
                                this.is2FA = true;
                                this.ClearErrors();
                            }
                            else
                            {
                                self.ShowDialog(response.data.errorMessage, 'E', 'dialog');
                                this.ReloadCaptcha();
                                this.model.captcha = '';
                                this.model.verificationcode = '';
                                this.is2FA = false;
                            }

                            this.processing = false;
                        })
                        .catch((error) =>
                        {
                            Utilities.log("Login catch error:", error);
                            this.processing = false;

                            this.ClearFieldErrors();

                            self.ShowDialog(error.data.errorMessage, 'E', 'dialog');

                        });
                }
                else
                {
                    var messages = {
                        errors: this.customErrorText,
                        infoBody: '',
                        messageType: 'E',
                        viewType: 'dialog',
                    };
                    this.$root.$emit('setUpDialog', messages);
                }
            },
            Authenticate()
            {
                if (this.ValidateBeforeAction('2FA'))
                {
                    this.processing = true;
                    var payload = {
                        action: 'ChangePasswordTwoFactorAuthentication',
                        formdata: {
                            formInputs: { email: this.model.email, password: this.model.password, newpassword1: this.model.newpassword1, newpassword2: this.model.newpassword2, verificationcode: this.model.verificationcode, },
                        },
                        captcha: this.model.captcha,
                    };
                    var self = this;
                    this.$store.dispatch("AccountAction", payload)
                        .then(response =>
                        {
                            if (response.data.errorCode == 0)
                            {
                                self.ShowDialog(this.$root.$i18n.t('IM_PasswordChangedHomePage'), 'B', 'dialog', undefined, function () {
                                    self.$router.push({ name: 'home' });
                                });
                            }
                            else
                            {
                                self.ShowDialog(response.data.errorMessage, 'E', 'dialog');
                            }
                            self.processing = false;
                        })
                        .catch((error) =>
                        {
                            Utilities.log("TwoFactorAuthentication catch error:", error);
                            this.processing = false;

                            this.ClearFieldErrors();
                            self.ShowDialog(error.data.errorMessage, 'E', 'dialog');

                        });
                }
            },
            ValidateBeforeAction: function (pageName)
            {
                this.ClearErrors();
                for (var ctrl in this.errors)
                {
                    var data = this.model[ctrl];

                    if (ctrl == 'email')
                    {
                        this.errors[ctrl] = (data == '');

                        if (!this.errors[ctrl])
                        {
                            var isEmailValidated = this.ValidateEmail(this.model.email);

                            if (!isEmailValidated)
                                this.customErrorText.push(this.$t('VE_WarnEmailMustBeInRightFormat'));

                            this.errors[ctrl] = (!isEmailValidated);
                        }

                    }
                    else
                    {
                        this.errors[ctrl] = (data == '');
                    }
                }
                if (this.captchTimer <= 0)
                {
                    this.customErrorText.push(this.$t('VE_CaptchaTiemout'));
                }
                this.PrepareErrorMessage(this.pageInputs[pageName]);

                return this.customErrorText.length == 0;
            },
            ValidateEmail: function (value)
            {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(value).toLowerCase());
            },
            ClearErrors: function ()
            {
                this.customErrorText = [];

                for (var reset in this.errors)
                    this.errors[reset] = false;
            },
            PrepareErrorMessage: function (pageInputs)
            {
                for (var err in this.errors)
                {
                    if (pageInputs.indexOf(err) > -1)
                    {
                        if (this.errors[err] == true)
                        {
                            if (this.model[err] == '')
                                this.customErrorText.push(this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t(this.keys[err]) }));
                        }
                    }
                }
            },
            decrementCaptchaTimer()
            {
                if (this.captchTimer != 0)
                {
                    this.captchTimer -= 1;
                    setTimeout(this.decrementCaptchaTimer, 1000);
                }
            },


            ReloadCaptcha()
            {
                var date = Math.floor(new Date().getTime()).toString();
                this.captchaUrl = "/api/Captcha?rnd3=" + date;
                this.captchTimer = 180;
                setTimeout(this.decrementCaptchaTimer, 1000);
                //this.$nextTick(() => this.captchaUrl = "/api/Captcha?rnd=" + Math.floor(new Date().getTime()).toString());
                //setTimeout(() => this.captchaUrl = "/api/Captcha?rnd=" + Math.floor(new Date().getTime()).toString(), 2000);
            }
        },
        mounted: function ()
        {
            console.log("#################### Change password ######################");
            this.is2FA = false;
            this.tab = 'Tab-1';
            window.setTimeout(this.ReloadCaptcha, 1000);

            var self = this;
            this.$store.dispatch('GetUserInfo').then(userInfo => {
                self.model.email = userInfo.data.result.Email;
                return;
            });
        },
    }
</script>